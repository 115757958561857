import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { getQueryParams } from './getQueryParams';
import { PAYMENT_METHODS } from 'lib/enums/payment';
import { getDefaults } from 'lib/enums/search';
import { dateFromString } from 'lib/date';
import { numberFromString } from 'lib/number';
import { arrayFromDelimitedString } from 'lib/array';

const DEFAULT_SORT_BY = getDefaults().sortBy;
const DEFAULT_PAYMENT_METHOD = PAYMENT_METHODS.CASH;

const param = (key) => createSelector(getQueryParams, (query) => query[key]);
const parsePayWith = (payWith) => (payWith ? String(payWith).toLowerCase() : DEFAULT_PAYMENT_METHOD);
const parseSearchType = (searchType) => (['list', 'map'].includes(searchType) ? searchType : 'list');
const parseFlag = (value) => value === 'true' || undefined;
const parseSortBy = (sortBy) => sortBy || DEFAULT_SORT_BY;

// the following are all prefixed with getQuery to distinguish them from similar named
// selectors that come from the search results
export const getQueryLocation = param('location');
export const getQueryPayWith = createSelector(param('payWith'), parsePayWith);
export const getQueryCheckIn = createSelector(param('checkIn'), dateFromString);
export const getQueryCheckOut = createSelector(param('checkOut'), dateFromString);
export const getQueryAdults = createSelector(param('adults'), numberFromString);
export const getQueryChildren = createSelector(param('children'), numberFromString);
export const getQueryInfants = createSelector(param('infants'), numberFromString);
export const getQuerySortBy = createSelector(param('sortBy'), parseSortBy);
export const getQueryPropertyTypes = createSelector(param('propertyTypes'), arrayFromDelimitedString);
export const getQueryMinPrice = createSelector(param('minPrice'), numberFromString);
export const getQueryMaxPrice = createSelector(param('maxPrice'), numberFromString);
export const getQueryMinStarRating = param('minStarRating');
export const getQueryMinTripadvisorRating = param('minTripadvisorRating');
export const getQuerySearchType = createSelector(param('searchType'), parseSearchType);
export const getQueryFacilities = createSelector(param('facilities'), arrayFromDelimitedString);
export const getQueryFreeCancellation = createSelector(param('freeCancellation'), parseFlag);
export const getQueryDepositPay = createSelector(param('depositPay'), parseFlag);
export const getQueryLuxuryOffer = createSelector(param('luxuryOffer'), parseFlag);
export const getQueryPage = createSelector(param('page'), numberFromString);
export const getQueryNeLat = param('neLat');
export const getQuerySwLat = param('swLat');
export const getQueryNeLng = param('neLng');
export const getQuerySwLng = param('swLng');
export const getQueryFeaturedPropertyId = param('featuredPropertyId');
export const getQueryFeaturedOfferId = param('featuredOfferId');
export const getQueryRecommended = param('recommended');
export const getQueryUtmCampaign = param('utm_campaign');
export const getQueryUtmSource = param('utm_source');
export const getQueryUtmMedium = param('utm_medium');
export const getQueryUtmContent = param('utm_content');
export const getQueryUtmTerm = param('utm_term');
export const getQueryTrvReference = param('trv_reference');
export const getQueryGclid = param('gclid');
export const getQueryClassicRewards = createSelector(param('classicRewards'), parseFlag);
export const getQuerySubRegions = createSelector(param('subRegions'), arrayFromDelimitedString);
export const getQueryRoomTypeId = param('roomTypeId');
export const getQueryPropertyId = param('propertyId');
export const getQueryOfferId = param('offerId');
export const getTravelType = param('travelType');
export const getAppNavigationIcon = param('appNavigationIcon');

export const getQueryOccupants = createStructuredSelector({
  adults: getQueryAdults,
  children: getQueryChildren,
  infants: getQueryInfants,
});

export const querySelectors = {
  location: getQueryLocation,
  payWith: getQueryPayWith,
  checkIn: getQueryCheckIn,
  checkOut: getQueryCheckOut,
  adults: getQueryAdults,
  children: getQueryChildren,
  infants: getQueryInfants,
  sortBy: getQuerySortBy,
  propertyTypes: getQueryPropertyTypes,
  minPrice: getQueryMinPrice,
  maxPrice: getQueryMaxPrice,
  minStarRating: getQueryMinStarRating,
  minTripadvisorRating: getQueryMinTripadvisorRating,
  searchType: getQuerySearchType,
  facilities: getQueryFacilities,
  freeCancellation: getQueryFreeCancellation,
  depositPay: getQueryDepositPay,
  page: getQueryPage,
  neLat: getQueryNeLat,
  swLat: getQuerySwLat,
  neLng: getQueryNeLng,
  swLng: getQuerySwLng,
  featuredPropertyId: getQueryFeaturedPropertyId,
  featuredOfferId: getQueryFeaturedOfferId,
  utm_campaign: getQueryUtmCampaign,
  utm_source: getQueryUtmSource,
  utm_medium: getQueryUtmMedium,
  utm_content: getQueryUtmContent,
  utm_term: getQueryUtmTerm,
  trv_reference: getQueryTrvReference,
  gclid: getQueryGclid,
  classicRewards: getQueryClassicRewards,
  subRegions: getQuerySubRegions,
  roomTypeId: getQueryRoomTypeId,
  propertyId: getQueryPropertyId,
  offerId: getQueryOfferId,
  travelType: getTravelType,
  appNavigationIcon: getAppNavigationIcon,
};

/**
 * Warning: avoid using this selector in components with `useSelector()`. Use individual selectors instead
 */
export const getFullKnownQuery = createStructuredSelector(querySelectors);
